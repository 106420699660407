.playground {
  height: -moz-min-content;
  height: min-content;
  max-height: calc(100vh - 54px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
}
.playground::-webkit-scrollbar {
  width: 12px;
}
.playground::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.playground::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}/*# sourceMappingURL=playground.css.map */