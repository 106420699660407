.header {
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .headerLeft {
    display: flex;
    align-items: center;
    margin-left: 20px;
    img {
      height: 50px;
      width: 70px;
    }
  }
  .headerRight {
    .hrlnksIc {
      height: 15px;
      width: 15px;
      fill: var(--text-color);
      cursor: pointer;
      margin: 0 10px;
    }
  }
}
