.newproduct {
  padding: 1rem;
  .newproduct-container {
    .newproductwrapper {
      .newproduct-form {
        .newprod-formGroup {
          margin-bottom: 1rem;
          label {
            margin-bottom: 10px;
          }
          input,
          textarea,
          select {
            padding: 10px;
            outline: none;
            border: 1px solid #000;
            border-radius: 5px;
            width: calc(100% - 20px);
            margin-top: 5px;
          }
          textarea {
            min-height: 100px;
          }
          select {
            width: unset !important;
          }
          .input-field-row {
            display: flex;
            margin-bottom: 22px;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin: 15px 0px;
            .input-file-row {
              width: 100%;
              position: relative;
              @media (max-width: 480px) {
                width: 100%;
              }
              .project_brief {
                display: flex;
                padding: 18px 20px;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;
                cursor: pointer;
                border: 1px dashed var(--text-color);
                border-radius: 5px;
                .file-content {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  position: relative;
                  width: 80%;
                  @media (max-width: 480px) {
                    width: 100%;
                  }
                  .icon {
                    margin-right: 15px;
                    img {
                      max-width: 100%;
                      height: auto;
                    }
                  }
                  .content-label {
                    font-size: 16px;
                    letter-spacing: -0.17px;
                    line-height: 22px;
                    color: var(--text-primary);
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    width: 800px;
                    overflow-x: scroll;
                    &::-webkit-scrollbar {
                      scrollbar-color: rgb(35, 42, 46);
                      width: 5px;
                      max-height: 3px;
                      border-radius: 23px;
                      background-color: #ffffff00;
                    }
                    .filenames-list {
                      padding: 8px 15px;
                      border-radius: 23px;
                      font-size: 0.9rem;
                      text-decoration: none;
                      color: #eaeaea;
                      background-color: #505050;
                      margin-right: 3px;
                      width: min-content;
                      white-space: nowrap;
                      &:hover {
                        background-color: #797979;
                      }
                    }
                  }
                }
                .row-wrap {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  @media (max-width: 480px) {
                    display: none;
                  }
                  .button {
                    background: #2ac6ff;
                    border-radius: 4px;
                    padding: 9px 23px 11px;
                    font-size: 16px;
                    letter-spacing: 1.6px;
                    color: rgb(255, 255, 255);
                    text-transform: uppercase;
                    font-weight: 500;
                    z-index: unset !important;
                  }
                }
              }
              .file-field {
                height: 100%;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                width: 100%;
                top: 0px;
                left: 0px;
                cursor: pointer;
              }
            }
          }
        }
        .newprodsub-btn {
          padding: 10px 20px;
          border-radius: 23px;
          border: none;
          outline: none;
          background: linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #3584e4);
          color: #fff;
          font-weight: 700;
          letter-spacing: 1px;
        }
      }
    }
  }
}
