.admprofile {
  .profcontainer {
    .profwrapper {
      .pageTitle {
        font-size: 1.5rem;
        text-transform: uppercase;
      }
      .proftopcard {
        width: calc(100% - 20px);
        border-radius: 15px;
        background-color: #fff;
        .pcardoneTop {
          display: flex;
          padding: 10px;
          .cardprofImage {
            .profilePosRelative {
              position: relative;
              .profileimage {
                position: relative;
                width: 180px;
                height: 180px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 5px !important;
                vertical-align: middle;
                border-style: none;
                max-width: 100%;
              }
              .profileOnlineDot {
                color: #fff !important;
                height: 1px;
                border-radius: 50rem !important;
                background-color: #24d5b8 !important;
                right: -10px;
                position: absolute;
                border: 3px solid #fff;
                padding: 6px;
                margin-top: 6px;
              }
            }
          }
          .cardprofTexts {
            padding: 5px;
            margin-left: 20px;
            .profName {
              font-size: 1.4rem;
            }
            .profdetails {
              margin: 10px 0;
              strong {
                font-weight: 900;
              }
            }
          }
        }
        .pcardoneBottom {
          border-top: 1px solid #acacac;
          padding: 5px;
          .proftabs {
            display: flex;
            align-items: center;
            .ptablnk {
              margin: 5px 20px;
              cursor: pointer;
              font-family: sans-serif;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
