.users .user-view {
  padding: 10px;
}
.users .user-view .user-view-wrapper .user-view-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-left: 2rem;
}
.users .user-view .user-view-wrapper .user-view-header .userViewbackIc {
  cursor: pointer;
  padding: 5px;
}
.users .user-view .user-view-wrapper .user-view-header .userViewbackIc:hover {
  background-color: #cccccc;
  border-radius: 50%;
  overflow: hidden;
}
.users .user-view .user-view-wrapper .user-view-header h1 {
  margin-top: -5px;
}
.users .user-view .user-view-wrapper .users-view-body {
  padding: 20px;
  margin-left: 20px;
  border: 1px solid #222222;
  border-radius: 5px;
  margin-top: 2rem;
}
.users .user-view .user-view-wrapper .users-view-body .userview-details {
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
.users .user-view .user-view-wrapper .users-view-body .userview-details p {
  font-size: 2rem;
}
.users .user-view .user-view-wrapper .users-view-body .userview-details p span {
  font-size: 0.8rem;
  color: azure;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.users .user-view .user-view-wrapper .users-view-body .userview-details p .user-pass-secure {
  background-color: rgb(76, 160, 76);
}
.users .user-view .user-view-wrapper .users-view-body .userview-details p .user-pass-insecure {
  background-color: rgb(252, 97, 97);
}
.users .user-view .user-view-wrapper .users-view-body .userview-details button {
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #2ac6ff;
  border: 1px solid #2ac6ff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  align-items: center;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.users .user-view .user-view-wrapper .users-view-body .userview-details button:hover {
  color: rgb(109, 216, 255);
  background-color: rgb(233, 233, 233);
  transition: 0.3s;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, auto));
  gap: 1em;
  margin: 20px;
  max-height: 77vh;
}
@media (max-width: 1200px) {
  .users .user-view .user-view-wrapper .users-view-body .order_products_details {
    grid-template-columns: repeat(3, minmax(200px, auto));
  }
}
@media (max-width: 920px) {
  .users .user-view .user-view-wrapper .users-view-body .order_products_details {
    grid-template-columns: repeat(2, minmax(200px, auto));
  }
}
@media (max-width: 620px) {
  .users .user-view .user-view-wrapper .users-view-body .order_products_details {
    grid-template-columns: repeat(1, minmax(200px, auto));
  }
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem {
  overflow: hidden;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .ppreloaded {
  position: relative;
  height: 100% !important;
  width: 100%;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .ppreloaded .pprecenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .ppreloaded .pprecenter p {
  color: #fff;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .ppreloaded .pprecenter .loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .ppreloaded .pprecenter .loader::after,
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .ppreloaded .pprecenter .loader::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .ppreloaded .pprecenter .loader::after {
  animation-delay: 1s;
}
@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop {
  width: 100% !important;
  min-width: 400px;
  height: 300px;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  z-index: 8;
  background-color: #000000;
}
@media (max-width: 480px) {
  .users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop {
    min-width: 100% !important;
  }
}
@media (max-width: 1366px) {
  .users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop {
    min-width: 100% !important;
  }
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseimageprev {
  position: absolute;
  z-index: 7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 0 0;
     object-position: 0 0;
  transition: all 200ms;
  -webkit-mask-image: linear-gradient(180deg, #000, transparent);
  mask-image: linear-gradient(180deg, #000, transparent);
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaytopswcase {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaytopswcase .swcseaddtocart {
  background-color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 25px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: -moz-min-content !important;
  height: min-content !important;
  transition: 0.3s ease;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaytopswcase .swcseaddtocart .plus {
  height: 15px;
  width: 15px;
  fill: #000;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaytopswcase .swcseaddtocart .addtocartswcasetexts {
  font-size: 0.7rem;
  white-space: nowrap;
  opacity: 1;
  margin-left: 5px !important;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaytopswcase .swcaseitemlike {
  position: relative;
  z-index: 10;
  cursor: pointer !important;
  margin-right: 5px;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 25px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: -moz-min-content !important;
  height: min-content !important;
  transition: 0.3s ease;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaytopswcase .swcaseitemlike .likeheart {
  fill: red;
  height: 15px;
  width: 15px;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaybtm {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  margin: 10px;
  width: calc(100% - 20px);
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaybtm .shcaseshopicon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  cursor: pointer;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaybtm .shcaseshopicon img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 0 0;
     object-position: 0 0;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaybtm .swprodprice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  cursor: pointer;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaybtm .swprodprice .prodnameswcase {
  font-size: 0.9rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  display: -webkit-box;
  line-height: 1rem !important;
  max-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: pointer;
  width: 75%;
  color: #fff;
  font-weight: 400;
}
.users .user-view .user-view-wrapper .users-view-body .order_products_details .showcaseitem .showcaseitemtop .swcaseoverlay .overlaybtm .swprodprice .proswprice {
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 25px;
  margin: 5px;
  font-size: 0.7rem;
  white-space: nowrap;
}/*# sourceMappingURL=users.css.map */