.admprofile .profcontainer .profwrapper .pageTitle {
  font-size: 1.5rem;
  text-transform: uppercase;
}
.admprofile .profcontainer .profwrapper .proftopcard {
  width: calc(100% - 20px);
  border-radius: 15px;
  background-color: #fff;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneTop {
  display: flex;
  padding: 10px;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneTop .cardprofImage .profilePosRelative {
  position: relative;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneTop .cardprofImage .profilePosRelative .profileimage {
  position: relative;
  width: 180px;
  height: 180px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px !important;
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneTop .cardprofImage .profilePosRelative .profileOnlineDot {
  color: #fff !important;
  height: 1px;
  border-radius: 50rem !important;
  background-color: #24d5b8 !important;
  right: -10px;
  position: absolute;
  border: 3px solid #fff;
  padding: 6px;
  margin-top: 6px;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneTop .cardprofTexts {
  padding: 5px;
  margin-left: 20px;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneTop .cardprofTexts .profName {
  font-size: 1.4rem;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneTop .cardprofTexts .profdetails {
  margin: 10px 0;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneTop .cardprofTexts .profdetails strong {
  font-weight: 900;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneBottom {
  border-top: 1px solid #acacac;
  padding: 5px;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneBottom .proftabs {
  display: flex;
  align-items: center;
}
.admprofile .profcontainer .profwrapper .proftopcard .pcardoneBottom .proftabs .ptablnk {
  margin: 5px 20px;
  cursor: pointer;
  font-family: sans-serif;
  font-weight: 600;
}/*# sourceMappingURL=profile.css.map */