.app .homecontainer {
  margin: 0 5%;
}
@media (max-width: 480px) {
  .app .homecontainer {
    margin: 0;
  }
}
.app .homecontainer .homewrapper .homelayout {
  display: flex;
}
.app .homecontainer .homewrapper .homelayout .hmelayright {
  flex: 1;
}/*# sourceMappingURL=home.css.map */