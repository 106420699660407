.users {
  .user-view {
    padding: 10px;
    .user-view-wrapper {
      .user-view-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
        margin-left: 2rem;
        .userViewbackIc {
          cursor: pointer;
          padding: 5px;
          &:hover {
            background-color: #cccccc;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        h1 {
          margin-top: -5px;
        }
      }
      .users-view-body {
        padding: 20px;
        margin-left: 20px;
        border: 1px solid #222222;
        border-radius: 5px;
        margin-top: 2rem;
        .userview-details {
          padding: 10px 0;
          display: flex;
          align-items: center;
          gap: 20px;
          p {
            font-size: 2rem;
            span {
              font-size: 0.8rem;
              color: azure;
              padding: 5px;
              border-radius: 10px;
              margin-bottom: 5px;
              cursor: pointer;
              user-select: none;
            }
            .user-pass-secure {
              background-color: rgb(76, 160, 76);
            }
            .user-pass-insecure {
              background-color: rgb(252, 97, 97);
            }
          }
          button {
            border-radius: 10px;
            padding: 10px 20px;
            background-color: #2ac6ff;
            border: 1px solid #2ac6ff;
            position: relative;
            overflow: hidden;
            z-index: 1;
            text-align: center;
            line-height: 19px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            color: rgb(255, 255, 255);
            -webkit-box-align: center;
            align-items: center;
            transition: color 0.15s ease-in-out 0s;
            display: inline-block;
            user-select: none;
            &:hover {
              color: rgb(109, 216, 255);
              background-color: rgb(233, 233, 233);
              transition: 0.3s;
            }
          }
        }
        .order_products_details {
          display: grid;
          grid-template-columns: repeat(3, minmax(200px, auto));
          gap: 1em;
          margin: 20px;
          @media (max-width: 1200px) {
            grid-template-columns: repeat(3, minmax(200px, auto));
          }
          @media (max-width: 920px) {
            grid-template-columns: repeat(2, minmax(200px, auto));
          }
          @media (max-width: 620px) {
            grid-template-columns: repeat(1, minmax(200px, auto));
          }
          max-height: 77vh;
          .showcaseitem {
            overflow: hidden;
            .ppreloaded {
              position: relative;
              height: 100% !important;
              width: 100%;
              .pprecenter {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                p {
                  color: #fff;
                }
                .loader {
                  width: 48px;
                  height: 48px;
                  display: inline-block;
                  position: relative;
                }
                .loader::after,
                .loader::before {
                  content: '';
                  box-sizing: border-box;
                  width: 48px;
                  height: 48px;
                  border-radius: 50%;
                  border: 2px solid #fff;
                  position: absolute;
                  left: 0;
                  top: 0;
                  animation: animloader 2s linear infinite;
                }
                .loader::after {
                  animation-delay: 1s;
                }

                @keyframes animloader {
                  0% {
                    transform: scale(0);
                    opacity: 1;
                  }
                  100% {
                    transform: scale(1);
                    opacity: 0;
                  }
                }
              }
            }
            .showcaseitemtop {
              width: 100% !important;
              min-width: 400px;
              height: 300px;
              overflow: hidden;
              border-radius: 15px;
              position: relative;
              z-index: 8;
              background-color: #000000;
              @media (max-width: 480px) {
                min-width: 100% !important;
              }
              @media (max-width: 1366px) {
                min-width: 100% !important;
              }
              .swcaseimageprev {
                position: absolute;
                z-index: 7;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: 0 0;
                transition: all 200ms;
                //transform: scale(1.2);
                -webkit-mask-image: linear-gradient(180deg, #000, transparent);
                mask-image: linear-gradient(180deg, #000, transparent);
              }
              .swcaseoverlay {
                position: absolute;
                z-index: 9;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .overlaytopswcase {
                  display: flex;
                  justify-content: space-between;
                  margin: 20px 10px;
                  .swcseaddtocart {
                    background-color: #fff;
                    cursor: pointer;
                    padding: 5px 10px;
                    border-radius: 25px;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    height: min-content !important;
                    transition: 0.3s ease;
                    .plus {
                      height: 15px;
                      width: 15px;
                      fill: #000;
                    }
                    .addtocartswcasetexts {
                      font-size: 0.7rem;
                      white-space: nowrap;
                      opacity: 1;
                      margin-left: 5px !important;
                    }
                  }
                  .swcaseitemlike {
                    position: relative;
                    z-index: 10;
                    cursor: pointer !important;
                    margin-right: 5px;
                    background-color: #fff;
                    padding: 5px 10px;
                    border-radius: 25px;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    height: min-content !important;
                    transition: 0.3s ease;
                    .likeheart {
                      fill: red;
                      height: 15px;
                      width: 15px;
                    }
                  }
                }
                .overlaybtm {
                  position: absolute;
                  bottom: 0;
                  display: flex;
                  align-items: center;
                  margin: 10px;
                  width: calc(100% - 20px);
                  .shcaseshopicon {
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 10px;
                    cursor: pointer;
                    img {
                      height: 100%;
                      width: 100%;
                      object-fit: cover;
                      object-position: 0 0;
                    }
                  }
                  .swprodprice {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1;
                    cursor: pointer;
                    .prodnameswcase {
                      font-size: 0.9rem;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 1;
                      word-wrap: break-word;
                      display: -webkit-box;
                      line-height: 1rem !important;
                      max-height: 2rem;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      word-break: break-word;
                      cursor: pointer;
                      width: 75%;
                      color: #fff;
                      font-weight: 400;
                    }
                    .proswprice {
                      padding: 5px 10px;
                      background-color: #fff;
                      border-radius: 25px;
                      margin: 5px;
                      font-size: 0.7rem;
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
