.sidebar {
  height: 100vh;
  transition: width 0.5s ease;
  width: 180px;
  display: flex;
  flex-direction: column;
  .sidebTop {
    display: flex;
    align-items: center;
    .sbBarsIc {
      height: 25px;
      width: 25px;
      margin: 20px;
      cursor: pointer;
      &:hover {
        margin: 12px;
        border: 8px solid #a8a8a8;
        background-color: #a8a8a8;
        border-radius: 50%;
      }
    }
    .sidebarLogo {
      transition: 0.5s ease;
      h2 {
        margin-left: 10px;
      }
    }
  }
  .sidebarnavigation {
    padding: 5px;
    background: linear-gradient(-45deg, #00a0ffdb, #0048a2);
    border-radius: 5px;
    margin-left: 15px;
    transition: width 0.5s ease;
    flex-grow: 1;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .sidebarnavtop {
      flex-grow: 1;
    }
    .sidebarnavtop,
    .sidebarnavbottom {
      .sidebarnavlink {
        display: flex;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        margin: 10px 0;
        &:hover {
          ~ .sbNavIcContainer > .sbNavIc,
          ~ span {
            fill: #fff;
            color: #fff;
          }
        }
        .sbNavIcContainer {
          height: 15px;
          width: 15px;
          padding: 5px 0;
          margin-right: 12px;
          margin-top: -3px;
          margin-left: 7px;
          cursor: pointer;
          .sbNavIc {
            height: 15px;
            width: 15px;
            fill: #e6e6e6;
            &:hover {
              fill: #fff;
            }
          }
        }
        span {
          font-size: 0.9rem;
          font-weight: 800;
          white-space: nowrap !important;
          color: #e6e6e6;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
.sidemin {
  width: 70px !important;
  overflow: hidden !important;
  .sidebarnavigation {
    width: 30px !important;
    overflow: hidden !important;
  }
}
