// Light theme
:root {
  --background-color: #f2f1ff;
  --text-color: #2b2b2b;
  --text-color-inv: #e6e6e6;
  --background-modals: #fff;
}

//dark theme
[data-theme='dark'] {
  --background-color: #171c28;
  --text-color: #e6e6e6;
  --text-color-inv: #2b2b2b;
  --background-modals: #2e2e2e;
}
