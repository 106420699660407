.showcasecont {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, auto));
  gap: 1em;
  margin: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(200px, auto));
  }
  @media (max-width: 920px) {
    grid-template-columns: repeat(2, minmax(200px, auto));
  }
  @media (max-width: 620px) {
    grid-template-columns: repeat(1, minmax(200px, auto));
  }
  max-height: 77vh;
  .showcaseitem {
    overflow: hidden;
    .ppreloaded {
      position: relative;
      height: 100% !important;
      width: 100%;
      .pprecenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        p {
          color: #fff;
        }
        .loader {
          width: 48px;
          height: 48px;
          display: inline-block;
          position: relative;
        }
        .loader::after,
        .loader::before {
          content: '';
          box-sizing: border-box;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 2px solid #fff;
          position: absolute;
          left: 0;
          top: 0;
          animation: animloader 2s linear infinite;
        }
        .loader::after {
          animation-delay: 1s;
        }

        @keyframes animloader {
          0% {
            transform: scale(0);
            opacity: 1;
          }
          100% {
            transform: scale(1);
            opacity: 0;
          }
        }
      }
    }
    .showcaseitemtop {
      width: 100% !important;
      height: 300px;
      overflow: hidden;
      border-radius: 15px;
      position: relative;
      z-index: 8;
      background-color: #000000;
      @media (max-width: 480px) {
        min-width: 100% !important;
      }
      @media (max-width: 1366px) {
        min-width: 100% !important;
      }
      .swcaseimageprev {
        position: absolute;
        z-index: 7;
        top: 0;
        left: 0;
        height: 100%;
        transition: all 200ms;
        -webkit-mask-image: linear-gradient(180deg, #000, transparent);
        mask-image: linear-gradient(180deg, #000, transparent);
        width: 100%;
        aspect-ratio: auto 185 / 185;
        overflow-clip-margin: content-box;
        overflow: clip;
        object-fit: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: transform 0.3s ease-in-out 0.2ms;
      }
      .swcaseoverlay {
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .overlaytopswcase {
          display: flex;
          justify-content: space-between;
          margin: 20px 10px;
          .swcseaddtocart {
            background-color: #fff;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 25px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            height: min-content !important;
            transition: 0.3s ease;
            .plus {
              height: 15px;
              width: 15px;
              fill: #000;
            }
            .addtocartswcasetexts {
              font-size: 0.7rem;
              white-space: nowrap;
              opacity: 1;
              margin-left: 5px !important;
            }
          }
          .swcaseitemlike {
            position: relative;
            z-index: 10;
            cursor: pointer !important;
            margin-right: 5px;
            background-color: #fff;
            padding: 5px 10px;
            border-radius: 25px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            height: min-content !important;
            transition: 0.3s ease;
            .likeheart {
              fill: red;
              height: 15px;
              width: 15px;
            }
          }
        }
        .overlaybtm {
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;
          margin: 10px;
          width: calc(100% - 20px);
          .shcaseshopicon {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            cursor: pointer;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: 0 0;
            }
          }
          .swprodprice {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            cursor: pointer;
            .prodnameswcase {
              font-size: 0.9rem;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              word-wrap: break-word;
              display: -webkit-box;
              line-height: 1rem !important;
              max-height: 2rem;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-word;
              cursor: pointer;
              width: 75%;
              color: #fff;
              font-weight: 400;
            }
            .proswprice {
              padding: 5px 10px;
              background-color: #fff;
              border-radius: 25px;
              margin: 5px;
              font-size: 0.7rem;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
.productdetail {
  padding: 10px;
  .productdetailheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-left: 2rem;
    .productViewbackIc {
      cursor: pointer;
      padding: 5px;
      &:hover {
        background-color: #cccccc;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    h1 {
      margin-top: -5px;
    }
  }
  .productdetailbody {
    .products-view-body {
      padding: 20px;
      margin-left: 20px;
      border: 1px solid #222222;
      border-radius: 5px;
      margin-top: 2rem;
      display: flex;
      justify-content: flex-start;
      .prod-body-left {
        width: 40%;
        margin-right: 20px;
        .prod-image-wrapper {
          width: 100%;
          height: auto;
          overflow: hidden;
          border-radius: 23px;
        }
      }
      .prod-body-right {
        padding: 10px;
        width: 50%;
        .prod-edit-form {
          .prod-form-group {
            margin-bottom: 20px;
            .prod-input-edit {
              margin-top: 10px;
              padding: 10px;
              border-radius: 10px;
              width: calc(100% - 20px);
              outline: none;
              border: 1px solid #000;
            }
            .prod-input-edit-textarea {
              height: 200px;
              font-size: 1rem;
            }
            .input-field-row {
              display: flex;
              margin-bottom: 22px;
              -webkit-box-pack: justify;
              justify-content: space-between;
              margin: 15px 0px;
              .input-file-row {
                width: 100%;
                position: relative;
                .project_brief {
                  display: flex;
                  padding: 18px 20px;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: justify;
                  justify-content: space-between;
                  cursor: pointer;
                  border: 1px dashed var(--text-color);
                  border-radius: 5px;
                  .file-content {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    position: relative;
                    width: 80%;
                    @media (max-width: 480px) {
                      width: 100%;
                    }
                    .icon {
                      margin-right: 15px;
                      img {
                        max-width: 100%;
                        height: auto;
                      }
                    }
                    .content-label {
                      font-size: 16px;
                      letter-spacing: -0.17px;
                      line-height: 22px;
                      color: var(--text-primary);
                      display: flex;
                      flex-direction: row;
                      flex-wrap: nowrap;
                      width: 800px;
                      overflow-x: scroll;
                      &::-webkit-scrollbar {
                        scrollbar-color: rgb(35, 42, 46);
                        width: 5px;
                        max-height: 3px;
                        border-radius: 23px;
                        background-color: #ffffff00;
                      }
                      .filenames-list {
                        padding: 8px 15px;
                        border-radius: 23px;
                        font-size: 0.9rem;
                        text-decoration: none;
                        color: #eaeaea;
                        background-color: #505050;
                        margin-right: 3px;
                        width: min-content;
                        white-space: nowrap;
                        &:hover {
                          background-color: #797979;
                        }
                      }
                    }
                  }
                  .row-wrap {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    @media (max-width: 480px) {
                      display: none;
                    }
                    .button {
                      background: #2ac6ff;
                      border-radius: 4px;
                      padding: 9px 23px 11px;
                      font-size: 16px;
                      letter-spacing: 1.6px;
                      color: rgb(255, 255, 255);
                      text-transform: uppercase;
                      font-weight: 500;
                      z-index: unset !important;
                    }
                  }
                }
                .file-field {
                  height: 100%;
                  opacity: 0;
                  overflow: hidden;
                  position: absolute;
                  width: 100%;
                  top: 0px;
                  left: 0px;
                  cursor: pointer;
                }
              }
            }
          }
          .prod-ed-actions {
            .prod-update-btn {
              padding: 10px 20px;
              border-radius: 23px;
              border: none;
              outline: none;
              background: linear-gradient(
                -45deg,
                rgba(0, 160, 255, 0.86),
                #3584e4
              );
              color: #fff;
              font-weight: 700;
              letter-spacing: 1px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
