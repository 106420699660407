.app {
  .homecontainer {
    margin: 0 5%;
    @media (max-width: 480px) {
      margin: 0;
    }
    .homewrapper {
      .homelayout {
        display: flex;
        .hmelayright {
          flex: 1;
        }
      }
    }
  }
}
