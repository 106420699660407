.modalpopup {
  position: fixed;
  z-index: 1000 !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #00000078;
  .modalPopCont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 400px;
    border-radius: 23px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: var(--background-modals);
    padding: 20px;
  }
}
