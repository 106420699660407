.sidebar {
  height: 100vh;
  transition: width 0.5s ease;
  width: 180px;
  display: flex;
  flex-direction: column;
}
.sidebar .sidebTop {
  display: flex;
  align-items: center;
}
.sidebar .sidebTop .sbBarsIc {
  height: 25px;
  width: 25px;
  margin: 20px;
  cursor: pointer;
}
.sidebar .sidebTop .sbBarsIc:hover {
  margin: 12px;
  border: 8px solid #a8a8a8;
  background-color: #a8a8a8;
  border-radius: 50%;
}
.sidebar .sidebTop .sidebarLogo {
  transition: 0.5s ease;
}
.sidebar .sidebTop .sidebarLogo h2 {
  margin-left: 10px;
}
.sidebar .sidebarnavigation {
  padding: 5px;
  background: linear-gradient(-45deg, rgba(0, 160, 255, 0.8588235294), #0048a2);
  border-radius: 5px;
  margin-left: 15px;
  transition: width 0.5s ease;
  flex-grow: 1;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.sidebar .sidebarnavigation .sidebarnavtop {
  flex-grow: 1;
}
.sidebar .sidebarnavigation .sidebarnavtop .sidebarnavlink,
.sidebar .sidebarnavigation .sidebarnavbottom .sidebarnavlink {
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin: 10px 0;
}
.sidebar .sidebarnavigation .sidebarnavtop .sidebarnavlink:hover ~ .sbNavIcContainer > .sbNavIc,
.sidebar .sidebarnavigation .sidebarnavtop .sidebarnavlink:hover ~ span,
.sidebar .sidebarnavigation .sidebarnavbottom .sidebarnavlink:hover ~ .sbNavIcContainer > .sbNavIc,
.sidebar .sidebarnavigation .sidebarnavbottom .sidebarnavlink:hover ~ span {
  fill: #fff;
  color: #fff;
}
.sidebar .sidebarnavigation .sidebarnavtop .sidebarnavlink .sbNavIcContainer,
.sidebar .sidebarnavigation .sidebarnavbottom .sidebarnavlink .sbNavIcContainer {
  height: 15px;
  width: 15px;
  padding: 5px 0;
  margin-right: 12px;
  margin-top: -3px;
  margin-left: 7px;
  cursor: pointer;
}
.sidebar .sidebarnavigation .sidebarnavtop .sidebarnavlink .sbNavIcContainer .sbNavIc,
.sidebar .sidebarnavigation .sidebarnavbottom .sidebarnavlink .sbNavIcContainer .sbNavIc {
  height: 15px;
  width: 15px;
  fill: #e6e6e6;
}
.sidebar .sidebarnavigation .sidebarnavtop .sidebarnavlink .sbNavIcContainer .sbNavIc:hover,
.sidebar .sidebarnavigation .sidebarnavbottom .sidebarnavlink .sbNavIcContainer .sbNavIc:hover {
  fill: #fff;
}
.sidebar .sidebarnavigation .sidebarnavtop .sidebarnavlink span,
.sidebar .sidebarnavigation .sidebarnavbottom .sidebarnavlink span {
  font-size: 0.9rem;
  font-weight: 800;
  white-space: nowrap !important;
  color: #e6e6e6;
}
.sidebar .sidebarnavigation .sidebarnavtop .sidebarnavlink span:hover,
.sidebar .sidebarnavigation .sidebarnavbottom .sidebarnavlink span:hover {
  color: #fff;
}

.sidemin {
  width: 70px !important;
  overflow: hidden !important;
}
.sidemin .sidebarnavigation {
  width: 30px !important;
  overflow: hidden !important;
}/*# sourceMappingURL=sidebar.css.map */