.dashboard {
  .welcome {
    width: calc(100% - 40px);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #f9fafd;
    border: 1px solid #d8e2ef;
    margin-bottom: 20px;
    .welRight {
      display: flex;
      align-items: center;
      p {
        margin-right: 10px;
      }
      .calrangecont {
        .calrange {
          padding: 5px 10px;
        }
      }
    }
  }
  .genstats {
    width: calc(100% - 40px);
    border-radius: 10px;
    padding: 10px 20px;
    background-color: #f9fafd;
    border: 1px solid #d8e2ef;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .genscontact,
    .gensnewusrs,
    .gensnewleads {
      width: 32%;
      padding: 10px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tIc {
          display: flex;
          align-items: center;
          .tlcIc {
            height: 10px;
            width: 10px;
            margin-right: 10px;
          }
          span {
            text-transform: capitalize;
          }
        }
        .ellIc {
          height: 10px;
          width: 10px;
          margin: 5px;
          cursor: pointer;
        }
      }
    }
    .gensnewusrs {
      border-left: 1px solid #7a7a7a;
      border-right: 1px solid #7a7a7a;
    }
  }
  .dashcharts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding-top: 2rem;
    .linechart {
      min-width: 45%;
      flex: 1;
      height: 300px !important;
      background-color: #f9fafd;
      border: 1px solid #d8e2ef;
      border-radius: 10px;
      padding: 10px;
    }
    .piechart {
      height: 300px !important;
      background-color: #f9fafd;
      border: 1px solid #d8e2ef;
      border-radius: 10px;
      padding: 10px;
    }
    .dashInfolytics {
      height: 300px;
      background-color: #f9fafd;
      border: 1px solid #d8e2ef;
      border-radius: 10px;
      padding: 10px;
    }
  }
}
